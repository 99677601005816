import React from 'react'
import {Box, Container, Theme, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
    headerSection: {
        margin: theme.spacing(2, 0, 2)
    },
    title: {
        marginBottom: theme.spacing(2) + ' !important',
    }
}))

const InfoSection = (props: any) => {

    const classes = useStyles();

    const {title, description, justify} = props

    return (
        <Box component="div" className={classes.headerSection}>
            <Container maxWidth="md">
                <Typography variant="h1" align="center" className={classes.title}>
                    {title}
                </Typography>
                {Object.prototype.toString.call(description) === "[object Array]" ?
                    (
                        description.map((paragraph: string, index: number) => (
                            <Typography variant="body1" align={justify ? "justify" : "center"} key={index}>
                                {paragraph}
                            </Typography>
                        ))
                    ) : (
                        <Typography variant="body1" align={justify ? "justify" : "center"}>
                            {description}
                        </Typography>
                    )
                }
            </Container>
        </Box>
    );

};

export default InfoSection;
