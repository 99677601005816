import React from 'react'
import {Card, CardContent, CardMedia, Theme, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
    tile: {
        padding: theme.spacing(1),
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
        }
    },
    tileImage: {
        height: '300px'
    },
}))

const Tile = (props: any) => {

    const {title, subtitle, description, img} = props;

    const classes = useStyles();

    return (
        <Card className={classes.tile} square={true} elevation={0}>
            <CardMedia
                className={classes.tileImage}
                component="img"
                image={img}
                alt="green iguana"
            />
            <CardContent>
                <Typography gutterBottom variant="body2" component="div">
                    {subtitle}
                </Typography>
                <Typography gutterBottom variant="h4" component="div">
                    {title}
                </Typography>
                <Typography variant="body2" color="text.secondary" align="justify">
                    {description}
                </Typography>
            </CardContent>
        </Card>
    );

};

export default Tile;
