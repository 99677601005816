import React from 'react'
import {Fab, Theme} from "@mui/material";
import {Outlet} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import TopBar, {ScrollTop} from "./TopBar";
import {KeyboardArrowUp} from "@mui/icons-material";
import Footer from "./Footer";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: '100%'
    }
}));

const Layout = (props: any) => {

    const classes = useStyles();

    return (
        <React.Fragment>
            <TopBar/>
            <main className={classes.root}>
                <Outlet/>
                <ScrollTop {...props}>
                    <Fab color="secondary" size="medium" aria-label="scroll back to top">
                        <KeyboardArrowUp/>
                    </Fab>
                </ScrollTop>
            </main>
            <Footer/>
        </React.Fragment>
    )
}

export default Layout;