import React from 'react'
import {Box, Container, Grid, Theme, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import ContactForm from "./ContactForm";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        padding: theme.spacing(4, 2, 4, 2)
    },
    title: {
        marginBottom: theme.spacing(2) + ' !important'
    },
    contactBox: {
        marginBottom: theme.spacing(3)
    }
}))

const ContactPage = (props: any) => {

    const classes = useStyles();

    const {t} = useTranslation();

    return (
        <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={1}>
                <Grid item md={6} xs={12}>
                    <Typography variant="h3" className={classes.title}>
                        {t('contact_page_localization_title')}
                    </Typography>
                    <Box className={classes.contactBox}>
                        <Typography variant="h6">
                            Ul. Fredry 2
                        </Typography>
                        <Typography variant="h6">
                            30-605 Kraków
                        </Typography>
                    </Box>
                    <Typography variant="h3" className={classes.title}>
                        {t('contact_page_contact-data_title')}
                    </Typography>
                    <Box className={classes.contactBox}>
                        <Typography variant="h5">
                            {t('contact_page_secretariat_title')}
                        </Typography>
                        <Typography variant="body2">
                            +48 12 267 34 64
                        </Typography>
                        <Typography variant="body2">
                            +48 12 266 09 16
                        </Typography>
                        <Typography variant="body2">
                            +48 608 379 413
                        </Typography>
                        <Typography variant="body2">
                            proacem@proacem.pl
                        </Typography>
                    </Box>
                    <Box className={classes.contactBox}>
                        <Typography variant="h5">
                            {t('contact_page_tech-sales-department_title')}
                        </Typography>
                        <Typography variant="body2">
                            Bartłomiej Trzciński
                        </Typography>
                        <Typography variant="body2">
                            b.trzcinski@proacem.pl
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Typography variant="h3" className={classes.title}>
                        {t('contact_page_form-title')}
                    </Typography>
                    <ContactForm/>
                </Grid>
            </Grid>
        </Container>
    );

};

export default ContactPage;
