import React from 'react'
import {Container, Theme} from "@mui/material";
import {makeStyles} from "@mui/styles";
import TitleBanner from "./TitleBanner";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6)
    }
}))

const PageContainer = (props: any) => {

    const {children, title, img} = props;

    const classes = useStyles();

    return (
        <React.Fragment>
            <TitleBanner title={title}
                         img={img}/>
            <Container className={classes.container} maxWidth="lg">
                {children}
            </Container>
        </React.Fragment>
    );

};

export default PageContainer;
