import React from 'react';
import {CardMedia, Container, Theme, Typography} from "@mui/material";
import {error} from "../../assets";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
    notFoundImage: {
        minWidth: '200px',
        maxWidth: '350px',
        margin: theme.spacing(6) + ' auto'
    }
}))


const NoFoundPage = (props: any) => {

    const classes = useStyles();

    return (
        <Container maxWidth="lg">
            <CardMedia
                className={classes.notFoundImage}
                component="img"
                image={error}
            />
            <Typography variant="h2" align="center">
                Nie znaleziono strony
            </Typography>
        </Container>
    );
};

export default NoFoundPage;
