import React, {useState} from 'react'
import {Grid, List, ListItem, ListItemIcon, ListItemText, Theme, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import PageContainer from "../../components/PageContainer";
import {useTranslation} from "react-i18next";
import Teaser from "../../components/Teaser";
import {ProductDialog} from "./ProductDialog";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
    productImageAerowaneDnaSilosow1,
    productImageBezpylowyZaladunek1,
    productImageEX2,
    productImageFiltry1,
    productImageKompensatory1, productImageKompensatory2,
    productImagePompyPC1,
    productImageRurorynny1,
    productImageSluzyCelkowe,
    productImageZbiornikiBeztermMag1
} from "../../../assets/products";
import {homeImageProducts} from "../../../assets";

const useStyles = makeStyles((theme: Theme) => ({
    paragraph: {
        marginBottom: theme.spacing(2) + ' !important'
    }
}))

const products = [
    {
        title: 'products_page_product_5_title',
        content: 'products_page_product_5_content',
        img: productImageSluzyCelkowe
    },
    {
        title: 'products_page_product_6_title',
        content: 'products_page_product_6_content',
        img: productImageEX2
    },
    {
        title: 'products_page_product_7_title',
        content: 'products_page_product_7_content',
        img: productImageBezpylowyZaladunek1
    },
    {
        title: 'products_page_product_8_title',
        content: 'products_page_product_8_content',
        img: productImageZbiornikiBeztermMag1
    },
    {
        title: 'products_page_product_9_title',
        content: 'products_page_product_9_content',
        img: productImageFiltry1
    }

]

const firstProductListItems = [
    'products_page_product_1_dialog_item_1',
    'products_page_product_1_dialog_item_2',
    'products_page_product_1_dialog_item_3',
    'products_page_product_1_dialog_item_4',
    'products_page_product_1_dialog_item_5',
    'products_page_product_1_dialog_item_6',
    'products_page_product_1_dialog_item_7',
    'products_page_product_1_dialog_item_8',
    'products_page_product_1_dialog_item_9'
]
const fourthProductListItems = [
    'products_page_product_4_dialog_item_1',
    'products_page_product_4_dialog_item_2',
    'products_page_product_4_dialog_item_3',
    'products_page_product_4_dialog_item_4',
    'products_page_product_4_dialog_item_5',
    'products_page_product_4_dialog_item_6',
    'products_page_product_4_dialog_item_7',
    'products_page_product_4_dialog_item_8'
]

const ProductsPage = (props: any) => {

    const [openFirstProduct, setOpenFirstProduct] = useState(false);
    const [openSecondProduct, setOpenSecondProduct] = useState(false);
    const [openFourthProduct, setOpenFourthProduct] = useState(false);

    const classes = useStyles();

    const {t} = useTranslation();

    return (
        <PageContainer title={t('products_page_title')}
                       img={homeImageProducts}>
            <Grid container spacing={10}>
                <Grid item xs={12}>
                    <Teaser title={t('products_page_product_1_title')}
                            description={t('products_page_product_1_content')}
                            img={productImageRurorynny1}
                            buttonContent={t('common_teaser_button_read_more')}
                            onButtonClick={() => setOpenFirstProduct(true)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Teaser title={t('products_page_product_2_title')}
                            description={t('products_page_product_2_content')}
                            img={productImagePompyPC1}
                            buttonContent={t('common_teaser_button_read_more')}
                            onButtonClick={() => setOpenSecondProduct(true)}
                            reverse
                    />
                </Grid>
                <Grid item xs={12}>
                    <Teaser title={t('products_page_product_3_title')}
                            description={t('products_page_product_3_content')}
                            img={productImageAerowaneDnaSilosow1}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Teaser title={t('products_page_product_4_title')}
                            description={t('products_page_product_4_content')}
                            img={productImageKompensatory2}
                            buttonContent={t('common_teaser_button_read_more')}
                            onButtonClick={() => setOpenFourthProduct(true)}
                            reverse
                    />
                </Grid>
                {products.map((product, index) => (
                    <Grid item xs={12} key={index}>
                        <Teaser title={t(product.title)}
                                description={t(product.content)}
                                img={product.img}
                                reverse={index % 2 === 1}
                        />
                    </Grid>
                ))}
            </Grid>

            <ProductDialog
                open={openFirstProduct}
                onClose={() => setOpenFirstProduct(false)}
                img={productImageRurorynny1}
            >
                <Typography className={classes.paragraph} variant="h1">
                    {t('products_page_product_1_title')}
                </Typography>
                <Typography
                    className={classes.paragraph}
                    variant="body2"
                    align="justify">
                    {t('products_page_product_1_content')}
                </Typography>
                <Typography variant="body2" align="justify">
                    {t('products_page_product_1_dialog_list-text')}
                </Typography>
                <List dense>
                    {firstProductListItems.map((item, index) => (
                        <ListItem key={index}>
                            <ListItemIcon>
                                <FiberManualRecordIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body2" align="justify">
                                    {t(item)}
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    ))}
                </List>
            </ProductDialog>

            <ProductDialog
                open={openSecondProduct}
                onClose={() => setOpenSecondProduct(false)}
                img={productImagePompyPC1}
            >
                <Typography className={classes.paragraph} variant="h1">
                    {t('products_page_product_2_title')}
                </Typography>
                <Typography
                    className={classes.paragraph}
                    variant="body2"
                    align="justify">
                    {t('products_page_product_2_content')}
                </Typography>
                <Typography
                    className={classes.paragraph}
                    variant="body2"
                    align="justify">
                    {t('products_page_product_2_dialog_content_1')}
                </Typography>
                <Typography
                    className={classes.paragraph}
                    variant="body2"
                    align="justify">
                    {t('products_page_product_2_dialog_content_2')}
                </Typography>
                <Typography
                    className={classes.paragraph}
                    variant="body2"
                    align="justify">
                    {t('products_page_product_2_dialog_content_3')}
                </Typography>
            </ProductDialog>

            <ProductDialog
                open={openFourthProduct}
                onClose={() => setOpenFourthProduct(false)}
                img={productImageKompensatory2}
            >
                <Typography className={classes.paragraph} variant="h1">
                    {t('products_page_product_4_title')}
                </Typography>
                <Typography
                    className={classes.paragraph}
                    variant="body2"
                    align="justify">
                    {t('products_page_product_4_dialog_content_1')}
                </Typography>
                <Typography
                    className={classes.paragraph}
                    variant="body2"
                    align="justify">
                    {t('products_page_product_4_dialog_content_2')}
                </Typography>
                <Typography
                    className={classes.paragraph}
                    variant="body2"
                    align="justify">
                    {t('products_page_product_4_dialog_content_3')}
                </Typography>
                <Typography
                    className={classes.paragraph}
                    variant="body2"
                    align="justify">
                    {t('products_page_product_4_dialog_content_4')}
                </Typography>
                <Typography variant="body2" align="justify">
                    {t('products_page_product_4_dialog_list-text')}
                </Typography>
                <List dense>
                    {fourthProductListItems.map((item, index) => (
                        <ListItem key={index}>
                            <ListItemIcon>
                                <FiberManualRecordIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body2" align="justify">
                                    {t(item)}
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    ))}
                </List>
            </ProductDialog>
        </PageContainer>
    );

};

export default ProductsPage;
