import React, {useEffect} from 'react';
import './App.css';
import AppRouter from "./AppRouter";
import {defaultGlobalState, useGlobalState} from "./state/GlobalState";
import i18n from "../i18n";
import {findLanguage} from "./commons/Languages";

function App() {

    const [state, setState] = useGlobalState();
    const {firstEntry, language} = state;

    const setLanguageOnInit = () => {
        const storageLanguage = localStorage.getItem("locale");
        const language = storageLanguage !== null ? findLanguage(storageLanguage) : defaultGlobalState.language;
        setState({language: language})
    }

    /* eslint-disable */
    useEffect(() => {
        i18n.changeLanguage(language.code).then(r => {
        });
        if (!firstEntry) {
            localStorage.setItem("locale", language.code);
        }
    }, [language]);

    useEffect(() => {
        if (firstEntry) {
            setLanguageOnInit();
            setState({firstEntry: false})
        }
    }, []);


    return (
        <AppRouter/>
    );
}

export default App;
