import {De, Gb, Pl, Ru} from "react-flags-select";

export interface Languages {
    en: Language,
    pl: Language
}

export interface Language {
    code: string,
    element: JSX.Element
}

export const languages: Languages = {
    en: {
        code: "en",
        element: <Gb/>
    },
    pl: {
        code: "pl",
        element: <Pl/>
    },
}

export function findLanguage(code: string | null): Language {
    return Object.values(languages).find(language => language.code === code)
}

