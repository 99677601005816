import React from 'react'
import {makeStyles} from "@mui/styles";
import {
    Button,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Theme,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
    image: {
        maxHeight: '300px',
        objectFit: 'cover'
    }
}))

export const ProductDialog = (props: any) => {

    const {children, open, onClose, img} = props;

    const classes = useStyles();

    const {t} = useTranslation();

    const theme = useTheme();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullScreen={useMediaQuery(theme.breakpoints.down('md'))}
            fullWidth
        >
            <CardMedia
                className={classes.image}
                component="img"
                image={img}
            />
            <DialogContent>
                <DialogContentText>
                    {children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t('common_dialog_button_close')}</Button>
            </DialogActions>
        </Dialog>
    )
}