import React from 'react'
import {Box, Button, CardMedia, Paper, Theme, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
    infoMediaSection: {
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            position: 'static',
        },
        minHeight: '600px',
        height: 'auto',
        width: '100%'
    },
    image: {
        position: 'absolute',
        top: 0,
        [theme.breakpoints.up('md')]: {
            width: '80% !important',
            height: '600px',
        },
        [theme.breakpoints.down('md')]: {
            height: '400px',
        },
        [theme.breakpoints.down('sm')]: {
            position: 'static',
        },
        objectFit: 'cover'
    },
    imageRow: {
        right: 0,
    },
    imageRowReverse: {
        left: 0
    },
    content: {
        position: 'absolute',
        backgroundColor: theme.palette.secondary.light + ' !important',
        [theme.breakpoints.up('md')]: {
            top: 0,
            bottom: 0,
            marginTop: 'auto',
            marginBottom: 'auto',
            width: '50%',
            minHeight: '20%',
            maxHeight: '60%',
            overflow: 'hidden',
            height: 'auto',
            padding: theme.spacing(4)
        },
        [theme.breakpoints.down('md')]: {
            top: '45%',
            left: 0,
            right: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '80%',
            padding: theme.spacing(4)
        },
        [theme.breakpoints.down('sm')]: {
            position: 'static',
            top: '60%',
            width: '100%',
            boxSizing: 'border-box',
            padding: theme.spacing(2) + '!important'
        },
    },
    contentRow: {
        left: 0
    },
    contentRowReverse: {
        right: 0
    },
    title: {
        marginBottom: theme.spacing(3) + ' !important'
    },
    button: {
        marginTop: theme.spacing(3) + ' !important'
    }
}))

const Teaser = (props: any) => {

    const {title, description, img, reverse, buttonContent, onButtonClick} = props;

    const classes = useStyles();

    return (
        <Box className={classes.infoMediaSection}>
            <CardMedia
                className={clsx(classes.image, reverse ? classes.imageRowReverse : classes.imageRow)}
                component="img"
                image={img}
            />
            <Paper className={clsx(classes.content, reverse ? classes.contentRowReverse : classes.contentRow)}
                   elevation={0}
                   square>
                <Typography variant="h1" className={classes.title}>
                    {title}
                </Typography>
                <Typography variant="body1" align="justify">
                    {description}
                </Typography>
                {buttonContent && (
                    <Button className={classes.button} variant="outlined"
                            onClick={onButtonClick}>{buttonContent}</Button>
                )}
            </Paper>
        </Box>
    );

};

export default Teaser;
