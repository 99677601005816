import React from 'react'
import {Container, Grid, Theme} from "@mui/material";
import {makeStyles} from "@mui/styles";
import HomeCarousel from "./HomeCarousel";
import InfoSection from "../../components/InfoSection";
import Teaser from "../../components/Teaser";
import {homeImageProducts, homeImageRealizations, homeImageServices} from "../../../assets";
import {useTranslation} from "react-i18next";
import {routes} from "../../commons/Routes";
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
    homeContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}))


const HomePage = (props: any) => {

    const classes = useStyles();

    const {t} = useTranslation();

    const navigate = useNavigate();

    return (
        <React.Fragment>
            <HomeCarousel/>
            <Container className={classes.homeContainer} maxWidth="lg">
                <Grid container direction="column" spacing={15}>
                    <Grid item>
                        <InfoSection title={t('home_page_about-us_title')}/>
                        <InfoSection justify description={t('home_page_about-us_paragraph_1')}/>
                        <InfoSection justify description={t('home_page_about-us_paragraph_2')}/>
                        <InfoSection justify description={t('home_page_about-us_paragraph_3')}/>
                        <InfoSection justify description={t('home_page_about-us_paragraph_4')}/>
                    </Grid>
                    <Grid item>
                        <Teaser title={t('home_page_realizations_teaser_title')}
                                description={t('home_page_realizations_teaser_content')}
                                img={homeImageRealizations}
                                buttonContent={t('common_teaser_button_read_more')}
                                onButtonClick={() => navigate(routes.realizations)}
                        />
                    </Grid>
                    <Grid item>
                        <Teaser title={t('home_page_services_teaser_title')}
                                description={t('home_page_services_teaser_content')}
                                img={homeImageServices}
                                buttonContent={t('common_teaser_button_read_more')}
                                onButtonClick={() => navigate(routes.services)}
                                reverse
                        />
                    </Grid>
                    <Grid item>
                        <Teaser title={t('home_page_products_teaser_title')}
                                description={t('home_page_products_teaser_content')}
                                img={homeImageProducts}
                                buttonContent={t('common_teaser_button_read_more')}
                                onButtonClick={() => navigate(routes.products)}
                        />
                    </Grid>
                </Grid>

            </Container>
        </React.Fragment>
    );

};

export default HomePage;
