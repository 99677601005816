import React from 'react'
import {Grid} from "@mui/material";
import Tile from "../components/Tile";
import PageContainer from "../components/PageContainer";
import {useTranslation} from "react-i18next";
import {
    realizationImageAeracja, realizationImageBeztermMag,
    realizationImageCieploOdpadowe, realizationImagePaliwaAlternatywne,
    realizationImagePlynyWegla, realizationImageSystemyFiltracyjne, realizationImageTerminale,
    realizationImageUkladyPrzemialowe
} from "../../assets/realizations";
import {homeImageRealizations} from "../../assets";

const realizations = [
    {
        img: realizationImageCieploOdpadowe,
        title: 'realizations_page_tile_1_title',
        content: 'realizations_page_tile_1_content'
    },
    {
        img: realizationImageUkladyPrzemialowe,
        title: 'realizations_page_tile_2_title',
        content: 'realizations_page_tile_2_content'
    },
    {
        img: realizationImagePlynyWegla,
        title: 'realizations_page_tile_3_title',
        content: 'realizations_page_tile_3_content'
    },
    {
        img: realizationImageAeracja,
        title: 'realizations_page_tile_4_title',
        content: 'realizations_page_tile_4_content'
    },
    {
        img: realizationImageSystemyFiltracyjne,
        title: 'realizations_page_tile_5_title',
        content: 'realizations_page_tile_5_content'
    },
    {
        img: realizationImageBeztermMag,
        title: 'realizations_page_tile_6_title',
        content: 'realizations_page_tile_6_content'
    },
    {
        img: realizationImagePaliwaAlternatywne,
        title: 'realizations_page_tile_7_title',
        content: 'realizations_page_tile_7_content'
    },
    {
        img: realizationImageTerminale,
        title: 'realizations_page_tile_8_title',
        content: 'realizations_page_tile_8_content'
    },
];

const RealizationsPage = () => {

    const {t} = useTranslation();

    return (
        <PageContainer title={t('realizations_page_title')}
                       img={homeImageRealizations}>
            <Grid container spacing={3}>
                {realizations.map((realization, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                        <Tile img={realization.img}
                              title={t(realization.title)}
                              description={t(realization.content)}
                        />
                    </Grid>
                ))}
            </Grid>
        </PageContainer>
    );

};

export default RealizationsPage;
