import React from 'react';
import {Box, Container, Grid, Theme, Toolbar, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
    footer: {
        borderTop: '1px solid ' + theme.palette.divider,
        backgroundColor: theme.palette.secondary.light,
    },
    logo: {
        height: '100px'
    },
}));

const Footer = () => {

    const classes = useStyles();

    return (
        <Box component="footer" className={classes.footer}>
            <Container maxWidth="xl">
                <Toolbar>
                    <Grid container sx={{height: '100%'}}>
                        <Grid item xs={12} sx={{height: '100%'}}>
                            <Typography variant="body1">
                                © Proacem 2021. Wszystkie prawa zastrzeżone.
                            </Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
            </Container>
        </Box>
    );
};

export default Footer;