import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import {appTheme} from "./app/theme/AppTheme";
import {ThemeProvider} from "@mui/material";
import {GlobalStateProvider} from "./app/state/GlobalState";
import './i18n'
import {SnackbarProvider} from "notistack";

ReactDOM.render(
    <React.StrictMode>
        <GlobalStateProvider>
            <ThemeProvider theme={appTheme}>
                <SnackbarProvider maxSnack={3}>
                <App/>
                </SnackbarProvider>
            </ThemeProvider>
        </GlobalStateProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
