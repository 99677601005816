import React from 'react'
import {BrowserRouter, Routes} from "react-router-dom";
import Layout from "./theme/layout/Layout";
import HomePage from "./pages/home/HomePage";
import NoFoundPage from "./pages/NoFoundPage";
import {Route} from "react-router";
import RealizationsPage from "./pages/RealizationsPage";
import ContactPage from "./pages/contact/ContactPage";
import ProductsPage from "./pages/products/ProductsPage";
import ServicesPage from "./pages/ServicesPage";
import {routes} from './commons/Routes';

const AppRouter = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="" element={<Layout/>}>
                    <Route path={routes.home} element={<HomePage/>}/>
                    <Route path={routes.products} element={<ProductsPage/>}/>
                    <Route path={routes.services} element={<ServicesPage/>}/>
                    <Route path={routes.realizations} element={<RealizationsPage/>}/>
                    <Route path={routes.contact} element={<ContactPage/>}/>

                    <Route path="*" element={<NoFoundPage/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default AppRouter;