import React from 'react'
import {CardMedia, Container, Paper, Theme, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
    image: {
        minHeight: '200px',
        maxHeight: '300px',
        filter: 'brightness(65%)',
    },
    title: {
        position: 'absolute',
        bottom: '30%'
    }
}))

const TitleBanner = (props: any) => {

    const {title, img} = props;

    const classes = useStyles();

    return (
        <Paper sx={{position: 'relative'}}>
            <CardMedia
                className={classes.image}
                component="img"
                image={img}
            />
            <Container maxWidth="xl">
                <Typography color="white" variant="h1" className={classes.title}>
                    {title}
                </Typography>
            </Container>

        </Paper>
    );

};

export default TitleBanner;
