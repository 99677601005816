import React from 'react'
import {List, ListItem, ListItemIcon, ListItemText, Theme, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import PageContainer from "../components/PageContainer";
import {useTranslation} from "react-i18next";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {homeImageServices} from "../../assets";

const useStyles = makeStyles((theme: Theme) => ({
    listTextContent: {
        paddingTop: theme.spacing(3)
    }
}))

const firstContentList = [
    'services_page_content_list_1_item_1',
    'services_page_content_list_1_item_2',
    'services_page_content_list_1_item_3',
    'services_page_content_list_1_item_4',
    'services_page_content_list_1_item_5',
    'services_page_content_list_1_item_6',
    'services_page_content_list_1_item_7',
]

const secondContentList = [
    'services_page_content_list_2_item_1',
    'services_page_content_list_2_item_2',
    'services_page_content_list_2_item_3',
]

const thirdContentList = [
    'services_page_content_list_3_item_1',
    'services_page_content_list_3_item_2',
    'services_page_content_list_3_item_3',
]

const ServicesPage = (props: any) => {

    const classes = useStyles();

    const {t} = useTranslation();

    return (
        <PageContainer title={t('services_page_title')}
                       img={homeImageServices}>
            <Typography variant="body1" align="justify">
                {t('services_page_content_text')}
            </Typography>

            <Typography variant="body1" className={classes.listTextContent} align="justify">
                {t('services_page_content_list_1_text')}
            </Typography>
            <List dense>
                {firstContentList.map((item, index) => (
                    <ListItem key={index}>
                        <ListItemIcon>
                            <FiberManualRecordIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body2" align="justify">
                                {t(item)}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                ))}
            </List>

            <Typography variant="body1" className={classes.listTextContent} align="justify">
                {t('services_page_content_list_2_text')}
            </Typography>
            <List dense>
                {secondContentList.map((item, index) => (
                    <ListItem key={index}>
                        <ListItemIcon>
                            <FiberManualRecordIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body2" align="justify">
                                {t(item)}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                ))}
            </List>

            <Typography variant="body1" className={classes.listTextContent} align="justify">
                {t('services_page_content_list_3_text')}
            </Typography>
            <List dense>
                {thirdContentList.map((item, index) => (
                    <ListItem key={index}>
                        <ListItemIcon>
                            <FiberManualRecordIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body2" align="justify">
                                {t(item)}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
        </PageContainer>
    );

};

export default ServicesPage;
