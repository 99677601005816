import React, {useState} from 'react';
import {AppBar, Theme, Toolbar} from '@material-ui/core';
import {makeStyles} from "@mui/styles";
import {
    Box,
    IconButton,
    List,
    ListItem,
    Menu,
    MenuItem,
    SwipeableDrawer,
    Tab,
    Tabs,
    Typography,
    useScrollTrigger,
    Zoom
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {Link, useNavigate} from "react-router-dom";
import {logo} from "../../../assets";
import {Language, languages} from "../../commons/Languages";
import {useGlobalState} from "../../state/GlobalState";
import {useTranslation} from "react-i18next";
import {routes} from "../../commons/Routes";
import {useLocation} from "react-router";
import {common} from "@mui/material/colors";
import {Gb, Pl} from "react-flags-select";


const useStyles = makeStyles((theme: Theme) => ({
    appBar: {
        backgroundColor: theme.palette.common.white + ' !important',
    },
    logo: {
        position: 'absolute',
        height: '75%',
        "&:hover": {
            cursor: "pointer"
        }
    },
    tabIndicator: {
        height: '10px'
    },
    toolbarLanguageIcon: {
        marginRight: theme.spacing(1)
    },
    toolbarButtonIcon: {
        color: common.black + ' !important'
    },
    drawer: {
        width: '300px'
    }
}));

export function ScrollTop(props: any) {
    const {children, window} = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const anchor = (
            (event.target as HTMLDivElement).ownerDocument || document
        ).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };

    return (
        <Zoom in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{position: 'fixed', bottom: 16, right: 16}}
            >
                {children}
            </Box>
        </Zoom>
    );
}


function ElevationScroll(props: any) {
    const {children} = props;

    return React.cloneElement(children, {
        elevation: 0,
    });
}

const pages = [
    {
        langCode: 'products_page_app-bar',
        route: routes.products
    },
    {
        langCode: 'services_page_app-bar',
        route: routes.services
    },
    {
        langCode: 'realizations_page_app-bar',
        route: routes.realizations
    },
    {
        langCode: 'contact_page_app-bar',
        route: routes.contact
    }
];

const TopBar = (props: any) => {

    const classes = useStyles();

    const location = useLocation();

    const [state, setState] = useGlobalState();

    const {language} = state;

    const {t} = useTranslation();

    const navigate = useNavigate();

    const [langAnchorEl, setLangAnchorEl] = useState<null | HTMLElement>(null);
    const isLangMenuOpen = Boolean(langAnchorEl);
    const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);

    const handleLanguageMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setLangAnchorEl(event.currentTarget);
    };

    const handleLanguageMenuClose = () => {
        setLangAnchorEl(null);
    };

    const setLanguage = (language: Language) => {
        setState({language});
        handleLanguageMenuClose();
    }

    const path = location.pathname === "/" ? "/" + pages[0].route : location.pathname;

    const menuId = 'language-menu';
    const languagesMenu = (
        <Menu
            anchorEl={langAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isLangMenuOpen}
            onClose={handleLanguageMenuClose}
        >
            <MenuItem onClick={() => setLanguage(languages.en)}>
                <Gb className={classes.toolbarLanguageIcon}/>
                <Typography variant="body2">
                    {t('english')}
                </Typography>
            </MenuItem>
            <MenuItem onClick={() => setLanguage(languages.pl)}>
                <Pl className={classes.toolbarLanguageIcon}/>
                <Typography variant="body2">
                    {t('polish')}
                </Typography>
            </MenuItem>
        </Menu>
    );

    const drawer = (
        <SwipeableDrawer
            anchor="right"
            open={isDrawerOpen}
            onClose={() => setDrawerOpen(false)}
            onOpen={() => setDrawerOpen(true)}
        >
            <List className={classes.drawer}>
                {pages.map((page, index) => (
                    <ListItem key={index}
                              button
                              component={Link}
                              to={page.route}
                              selected={location.pathname === ('/' + page.route)}
                    >
                        <Typography variant="subtitle2">
                            {t(page.langCode)}
                        </Typography>
                    </ListItem>
                ))}
            </List>
        </SwipeableDrawer>
    )

    return (
        <Box component="header">
            <ElevationScroll {...props}>
                <AppBar color="secondary" className={classes.appBar} component="div">
                    <Toolbar>
                        <img className={classes.logo} src={logo} alt="logo" onClick={() => navigate(routes.home)}/>
                        <Box sx={{flexGrow: 1}}/>
                        <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                            <Tabs value={path}>
                                {pages.map((page, index) => (
                                    <Tab
                                        key={index}
                                        component={Link}
                                        to={page.route}
                                        label={t(page.langCode)}
                                        value={'/' + page.route}
                                    />
                                ))}
                            </Tabs>
                        </Box>
                        <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                            <IconButton
                                className={classes.toolbarButtonIcon}
                                size="large"
                                onClick={() => setDrawerOpen(true)}
                            >
                                <MenuIcon/>
                            </IconButton>
                        </Box>
                        <IconButton
                            size="small"
                            edge="end"
                            aria-controls={menuId}
                            onClick={handleLanguageMenuOpen}
                        >
                            {language.element}
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
            <Toolbar id="back-to-top-anchor"/>
            {languagesMenu}
            {drawer}
        </Box>
    );
};


export default TopBar;
