import React from 'react'
import {CardMedia, Container, Paper, Theme, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Carousel from 'react-material-ui-carousel';
import {sliderImage1, sliderImage2, sliderImage3, sliderImage4, sliderImage5} from "../../../assets";

const useStyles = makeStyles((theme: Theme) => ({
    carousel: {
        height: 'auto'
    },
    carouselItem: {
        position: 'relative'
    },
    carouselImage: {
        [theme.breakpoints.up('sm')]: {
            height: '500px'
        },
        [theme.breakpoints.down('sm')]: {
            height: '400px'
        },
        filter: 'brightness(70%)',
    },
    carouselTitle: {
        position: 'absolute',
        bottom: '30%'
    }
}))

const sliderImages = [
    sliderImage1,
    sliderImage2,
    sliderImage3,
    sliderImage4,
    sliderImage5
]

function CarouselItem(props: any) {

    const {title, img} = props;

    const classes = useStyles();

    return (
        <Paper className={classes.carouselItem}>
            <CardMedia
                className={classes.carouselImage}
                component="img"
                image={img}
            />
            <Container maxWidth="xl">
                <Typography color="white" variant="h1" className={classes.carouselTitle}>
                    {title}
                </Typography>
            </Container>

        </Paper>
    )
}

function HomeCarousel(props: any) {

    const classes = useStyles();

    return (
        <Carousel className={classes.carousel}
                  navButtonsProps={{
                      style: {
                          margin: '16px',
                          backgroundColor: 'white',
                          borderRadius: 0
                      }
                  }}
                  indicatorIconButtonProps={{
                      style: {
                          padding: '10px',
                      }
                  }}
                  activeIndicatorIconButtonProps={{
                      style: {
                          color: 'red'
                      }
                  }}
        >
            {sliderImages.map((image, index) => <CarouselItem key={index} img={image}/>)}
        </Carousel>
    );

}

export default HomeCarousel;
