import React, {useState} from 'react'
import {Button, Grid, TextField} from "@mui/material";
import emailjs from 'emailjs-com';
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";

interface ContactParamKeys {
    firstName: string,
    lastName: string,
    email: string;
    message: string
}

type ContactParamRecord = Record<keyof ContactParamKeys, string>;

const ContactForm = () => {

    const {t} = useTranslation();

    const {enqueueSnackbar} = useSnackbar();

    const [contactParams, setContactParams] = useState<ContactParamRecord>({
        firstName: "",
        lastName: "",
        email: "",
        message: ""
    });

    const validateForm = () => {
        return !(contactParams.firstName === "" || contactParams.lastName === "" || contactParams.email === "" || contactParams.message === "")
    }

    const validateEmail = () => {
        return String(contactParams.email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const handleChange = (event: any) => {
        const {name, value} = event.target;
        setContactParams({
            ...contactParams,
            [name]: value
        });
    };

    const sendEmail = () => {
        const isFormValid = validateForm();
        const isEmailValid = validateEmail();
        if (isFormValid) {
            if (isEmailValid) {
                emailjs.send('service_g1g1ozw', 'template_uhin2gk', contactParams, 'user_1Ej8zNfhjkbrqRVybKD0W')
                    .then(() => {
                        enqueueSnackbar(t('contact_form_send_success'), {variant: "success"});
                    }, () => {
                        enqueueSnackbar(t('contact_form_server_error'), {variant: "error"});
                    });
            } else {
                enqueueSnackbar(t('contact_form_email_invalid'), {variant: "warning"});
            }
        } else {
            enqueueSnackbar(t('contact_form_error'), {variant: "warning"});
        }
    }

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item md={6} xs={12}>
                    <TextField
                        type="text"
                        label={t('contact_form_first-name')}
                        value={contactParams.firstName}
                        onChange={handleChange}
                        name="firstName"
                        variant="outlined"
                        fullWidth/>
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField
                        type="text"
                        label={t('contact_form_last-name')}
                        value={contactParams.lastName}
                        onChange={handleChange}
                        name="lastName"
                        variant="outlined"
                        fullWidth/>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        type="email"
                        label={t('contact_form_email')}
                        value={contactParams.email}
                        onChange={handleChange}
                        name="email"
                        variant="outlined"
                        fullWidth/>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        type="text"
                        label={t('contact_form_message')}
                        value={contactParams.message}
                        onChange={handleChange}
                        name="message"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}/>
                </Grid>
                <Grid item container xs={12} justifyContent="flex-end">
                    <Button variant="outlined" onClick={sendEmail}>{t('contact_form_send-button')}</Button>
                </Grid>
            </Grid>
        </form>
    );

};

export default ContactForm;
